import {
  MoreVert,
  PlayArrow,
  SettingsApplications,
  Stop,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";

export default ({
  actions,
  onRunAction,
  onEditAction,
  onStopAction,
}: {
  actions: any[];
  onRunAction: (action: any) => void;
  onEditAction: (action: any) => void;
  onStopAction: (action: any) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAction, setSelectedAction] = useState<any>(null);

  const handleMenuOpen = (event: any, action: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedAction(action);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAction(null);
  };

  const handleRunAction = () => {
    if (selectedAction) {
      onRunAction(selectedAction);
    }
    handleMenuClose();
  };

  const handleStopAction = () => {
    if (selectedAction) {
      onStopAction(selectedAction);
    }
    handleMenuClose();
  };

  const handleEditAction = () => {
    if (selectedAction) {
      onEditAction(selectedAction);
    }
    handleMenuClose();
  };

  if (!actions || actions.length === 0) {
    return <></>;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ mb: 1 }} variant="h6">
        Table Actions
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Updated Date</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actions.map((action) => (
              <TableRow key={action.id}>
                <TableCell>{action.name}</TableCell>
                <TableCell>
                  <Chip
                    label={
                      action.type === "add_rows" ? "Add Rows" : "Update Rows"
                    }
                    size="small"
                    color="primary"
                    variant="filled"
                  />
                </TableCell>
                <TableCell>
                  {action.loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Chip
                      label={action.researchSummary ? "Completed" : "Ready"}
                      size="small"
                      color={action.researchSummary ? "primary" : "warning"}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {action.created_date
                    ? moment(action.created_date).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "-"}
                </TableCell>
                <TableCell>
                  {action.updated_date
                    ? moment(action.updated_date).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )
                    : "-"}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    size="small"
                    onClick={(e) => handleMenuOpen(e, action)}
                    disabled={action.loading}
                  >
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            sx={{
              display:
                selectedAction && selectedAction.loading ? "none" : "flex",
            }}
            onClick={handleRunAction}
          >
            <PlayArrow sx={{ mr: 1 }} />
            Run Action
          </MenuItem>
          <MenuItem
            sx={{
              display:
                selectedAction && selectedAction.loading ? "flex" : "none",
            }}
            onClick={handleStopAction}
          >
            <Stop sx={{ mr: 1 }} />
            Stop Action
          </MenuItem>
          <MenuItem onClick={handleEditAction}>
            <SettingsApplications sx={{ mr: 1 }} />
            Edit Action
          </MenuItem>
        </Menu>
      </TableContainer>
    </Box>
  );
};
