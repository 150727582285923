import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LoadingButton from "../../components/LoadingButton";

export default ({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: (name: string) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const handleClose = () => {
    setName("");
    onClose();
  };

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm(name);
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create Template</DialogTitle>
      <DialogContent>
        <Typography>
          Choose a name to describe your templates goal and confirm to share
          within your company
        </Typography>
        <TextField
          sx={{ mt: 3 }}
          fullWidth
          label={"Template Name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          onClick={() => handleConfirm()}
          disabled={name === "" || loading}
          variant={"contained"}
          loading={loading}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
