import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SequenceEditor from "./SequenceEditor";

export default () => {
  const [editorOpen, setEditorOpen] = useState(false);
  return (
    <Box>
      {!editorOpen && (
        <Box>
          <Box
            sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
            flex="1"
            flexDirection={"row"}
          >
            <Typography>
              Sequences is a beta feature with limited functionality at the
              moment. Please contact us if you have any questions or feedback.
            </Typography>
            <Button variant="outlined" onClick={() => setEditorOpen(true)}>
              Create Sequence
            </Button>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </Box>
      )}
      {editorOpen && (
        <Box>
          <IconButton
            sx={{ mr: 2 }}
            onClick={() => setEditorOpen(false)}
            color="primary"
          >
            <ArrowBack />
          </IconButton>
          <SequenceEditor />
        </Box>
      )}
    </Box>
  );
};
