import {
  Add,
  ArrowBack,
  CopyAll,
  DeleteOutline,
  ElectricBolt,
  PlayCircle,
  SettingsApplications,
  Stop,
  UploadFile,
  UploadFileOutlined,
  Webhook,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import {
  GridColumnHeaderParams,
  GridColumnMenu,
  GridRowSelectionModel,
  GridToolbar,
} from "@mui/x-data-grid";
import axios from "axios";
import { User } from "firebase/auth";
import React, { SyntheticEvent, useEffect, useState } from "react";
import LoadingIconButton from "../../components/LoadingIconButton";
import { Company } from "../../types";
import { hostname, parseError } from "../../utils";
import ActionTable from "./ActionTable";
import AddColumnDialog from "./AddColumnDialog";
import ImportModal from "./ImportModal";
import ResearchAssistantDialog from "./ResearchAssistantDialog";
import { StyledDataGrid } from "./StyledGrid";
import TemplateEditor from "./TemplateEditor";
import WebhookDialog from "./WebhookDialog";

export default ({
  mode,
  currentUser,
  getToken,
  eventListener,
  companyId,
  company,
  currentPlan,
  listSelected,
  setListSelected,
}: {
  mode: string;
  getToken: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
  company: Company;
  currentPlan: string;
  listSelected: any;
  setListSelected: (list: any) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState<Array<any>>([]);
  const [rows, setRows] = useState<Array<any>>([]);
  const [totalRows, setTotalRows] = useState(100);
  const [actions, setActions] = useState<Array<any>>([]);
  const [importOpen, setImportOpen] = useState(false);
  const [researchOpen, setResearchOpen] = useState(false);
  const [actionParameters, setActionParameters] = useState<any | null>(null);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });
  const [addColumnOpen, setAddColumnOpen] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [actionAnchor, setActionAnchor] = useState<any>(null);
  const [selectedActionId, setSelectedActionId] = useState(null);
  const [importEl, setImportEl] = useState<any>(null);
  const [webhookOpen, setWebhookOpen] = useState(false);
  const [createTemplateOpen, setCreateTemplateOpen] = useState(false);

  const originalPoll = (fn: any, end: any, tries: number) => {
    async function checkCondition(curr: number) {
      const stillLoadingList = await fn();
      console.log("result", stillLoadingList);
      if (curr < tries && stillLoadingList && stillLoadingList.length > 0) {
        setTimeout(checkCondition, 3500, curr + 1);
      } else {
        end();
      }
    }
    checkCondition(1);
  };

  const runTableAction = async (
    actionId: any,
    type: string,
    selectedRows?: GridRowSelectionModel,
    forceRun: boolean = false
  ) => {
    console.log("Running table action", actionId, type);
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/lists/${listSelected.id}/action/run`,
        {
          companyId,
          actionId: actionId,
          type: type,
          selectedRows: selectedRows,
          forceRun,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res) => {
        console.log("Res", res);
        originalPoll(
          silentGetList,
          () => console.log("completed polling"),
          100
        );
      })
      .catch((err) => {
        console.log(err);
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const stopTableAction = async (actionId: any, type: string) => {
    console.log("Running table action", actionId, type);
    const currentToken = await getToken();
    await axios
      .post(
        `${hostname}/lists/${listSelected.id}/action/stop`,
        {
          companyId,
          actionId,
          type,
        },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res) => {
        console.log("Res", res);
        originalPoll(silentGetList, () => console.log("completed polling"), 50);
      })
      .catch((err) => {
        console.log(err);
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getColumns = (res: any) => {
    return res.data.list.headers.map((x: any) => {
      var field: any = {
        field: x.name,
        headerName: x.name,
        editable: true,
        width: 195,
      };
      if (x.type === "action") {
        field["sortable"] = false;
        field["type"] = "action";
        field["editable"] = false;
        field["width"] = 230;
        field["headerId"] = x.id;
        field["loading"] = x.loading;
        field["actionParameters"] = x.actionParameters;
        field["autoRunEnabled"] = x.autoRunEnabled;
        field["renderHeader"] = (params: GridColumnHeaderParams) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <strong style={{ marginRight: "8px" }}>{x.name}</strong>
            <LoadingIconButton
              loading={x.loading}
              size="small"
              onClick={(event: SyntheticEvent) => {
                setActionAnchor(event.currentTarget);
                setSelectedActionId(x.id);
              }}
            >
              <PlayCircle />
            </LoadingIconButton>
          </Box>
        );
      }
      return field;
    });
  };

  const addRow = async (res: any) => {
    const currentToken = await getToken();
    return axios
      .post(
        `${hostname}/lists/${listSelected.id}/row`,
        { companyId },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res: any) => {
        if (res.data.row) {
          setRows((oldRows) => [...oldRows, { id: res.data.row.id }]);
        }
      });
  };

  const addColumn = async (name: string) => {
    setAddColumnOpen(false);
    const currentToken = await getToken();
    return axios
      .post(
        `${hostname}/lists/${listSelected.id}/header`,
        { companyId, name },
        {
          headers: { Authorization: `Bearer ${currentToken}` },
        }
      )
      .then((res: any) => {
        if (res.data.header) {
          setColumns((oldColumns) => [
            ...oldColumns,
            {
              field: res.data.header.name,
              headerName: res.data.header.name,
              editable: false,
              width: 200,
            },
          ]);
        }
      });
  };

  const isAnyHeaderLoading = (res: any) => {
    return res.data.list.headers.filter((x: any) => x.loading);
  };

  const isAnyActionsLoading = (res: any) => {
    return res.data.list.actions.filter((x: any) => x.loading);
  };

  const silentGetList = async () => {
    const currentToken = await getToken();
    return axios
      .get(`${hostname}/lists/${listSelected.id}`, {
        params: {
          companyId,
          page: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
        },
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        setColumns(getColumns(res));
        setRows(res.data.list.rows.items);
        setTotalRows(res.data.list.rows.total);
        setActions(res.data.list.actions);
        return isAnyHeaderLoading(res) + isAnyActionsLoading(res);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  function EditHeaderItem(props: any) {
    const { myCustomHandler, myCustomValue } = props;
    return (
      <MenuItem onClick={myCustomHandler}>
        <ListItemIcon>
          <SettingsApplications fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  function DeleteHeaderItem(props: any) {
    const { myCustomHandler, myCustomValue } = props;
    return (
      <MenuItem onClick={myCustomHandler}>
        <ListItemIcon>
          <DeleteOutline fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  function StopHeaderItem(props: any) {
    const { myCustomHandler, myCustomValue, loading } = props;
    console.log("loading", loading);
    return (
      <MenuItem
        sx={{ display: loading ? "flex" : "none" }}
        onClick={myCustomHandler}
      >
        <ListItemIcon>
          <Stop fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomValue}</ListItemText>
      </MenuItem>
    );
  }

  function CustomColumnMenu(props: any) {
    var slots = {};
    if (props.colDef.type === "action") {
      slots = {
        columnMenuUserItem: EditHeaderItem,
        stopHeaderItem: StopHeaderItem,
        deleteHeader: DeleteHeaderItem,
      };
    }
    return (
      <GridColumnMenu
        {...props}
        slots={slots}
        slotProps={{
          stopHeaderItem: {
            // set `displayOrder` for the new item
            displayOrder: 15,
            loading: props.colDef.loading,
            // Additional props
            myCustomValue: "Stop Action",
            myCustomHandler: () => {
              stopTableAction(props.colDef.headerId, "update_rows");
            },
          },
          columnMenuUserItem: {
            // set `displayOrder` for the new item
            displayOrder: 16,
            // Additional props
            myCustomValue: "Edit Action",
            myCustomHandler: () => {
              setResearchOpen(true);
              setActionParameters({
                actionId: props.colDef.headerId,
                action: "update_rows",
                autoRunEnabled: props.colDef.autoRunEnabled,
                ...props.colDef.actionParameters,
              });
            },
          },
          deleteHeader: {
            // set `displayOrder` for the new item
            displayOrder: 17,
            // Additional props
            myCustomValue: "Delete Action",
            myCustomHandler: () => {
              deleteHeader(props.colDef.headerId);
            },
          },
        }}
      />
    );
  }

  const deleteHeader = async (headerId: string) => {
    const currentToken = await getToken();
    await axios.delete(
      `${hostname}/lists/${listSelected.id}/header/${headerId}`,
      {
        headers: { Authorization: `Bearer ${currentToken}` },
      }
    );
    await getList();
  };

  const getList = async (): Promise<boolean> => {
    const currentToken = await getToken();
    setLoading(true);
    await axios
      .get(`${hostname}/lists/${listSelected.id}`, {
        params: {
          companyId,
          page: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
        },
        headers: { Authorization: `Bearer ${currentToken}` },
      })
      .then((res) => {
        console.log(res);
        setColumns(getColumns(res));
        setRows(res.data.list.rows.items);
        setTotalRows(res.data.list.rows.total);
        setActions(res.data.list.actions);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
    return true;
  };

  const saveRow = async (row: any) => {
    const currentToken = await getToken();
    return axios.put(
      `${hostname}/lists/${listSelected.id}/row`,
      { companyId, row },
      {
        headers: { Authorization: `Bearer ${currentToken}` },
      }
    );
  };

  const processRowUpdate = async (updatedRow: any) => {
    console.log("row update", updatedRow);
    await saveRow(updatedRow);
    return updatedRow;
  };

  const handleProcessRowUpdateError = async (event: any) => {
    console.log("handle error", event);
  };

  const createListTemplate = async (name: string) => {
    const currentToken = await getToken();
    await axios.post(
      `${hostname}/lists/${listSelected.id}/templates`,
      { companyId, name },
      {
        headers: { Authorization: `Bearer ${currentToken}` },
      }
    );
  };

  useEffect(() => {
    if (listSelected) {
      getList();
    }
  }, [paginationModel.page, listSelected]);

  if (!listSelected) {
    return <></>;
  }

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <IconButton
          sx={{ mr: 2 }}
          onClick={() => setListSelected(null)}
          color="primary"
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" sx={{ mr: 1, lineHeight: 2 }}>
          🚀 {listSelected.name}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={(e) => setImportEl(e.target)}
          sx={{ mr: 2 }}
          startIcon={<UploadFile />}
        >
          Import
        </Button>
        <Button
          sx={{ mr: 2 }}
          startIcon={<CopyAll />}
          onClick={() => setCreateTemplateOpen(true)}
        >
          Save as Template
        </Button>
        <Menu
          open={Boolean(importEl)}
          anchorEl={importEl}
          onClose={() => setImportEl(false)}
        >
          <MenuItem onClick={() => setImportOpen(true)}>
            <ListItemIcon>
              <UploadFileOutlined />
            </ListItemIcon>
            <ListItemText>Import CSV</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => setWebhookOpen(true)}>
            <ListItemIcon>
              <Webhook />
            </ListItemIcon>
            <ListItemText>Import from Webhook</ListItemText>
          </MenuItem>
        </Menu>
        <Button sx={{ mr: 2 }} startIcon={<Add />} onClick={addRow}>
          Add record
        </Button>
        <Button
          sx={{ mr: 2 }}
          startIcon={<Add />}
          onClick={() => setAddColumnOpen(true)}
        >
          Add Column
        </Button>
        <Button
          variant="outlined"
          onClick={(e: any) => {
            setAnchorEl(e.currentTarget);
          }}
          startIcon={<ElectricBolt />}
        >
          Table Actions
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={anchorEl !== null}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setResearchOpen(true);
              setAnchorEl(null);
            }}
          >
            Add Table Action
          </MenuItem>
          <Divider />
          {actions.map((action, index) => (
            <MenuItem
              key={index}
              onClick={(event) => {
                runTableAction(action.id, "add_rows");
                setAnchorEl(null);
              }}
            >
              Run: {action.name}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Paper
          variant="outlined"
          sx={{ height: "calc(100vh - 320px)", width: "100%" }}
        >
          <StyledDataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            pageSizeOptions={[25, 50, 100]}
            rowCount={totalRows}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={(e) => setPaginationModel(e)}
            slots={{
              toolbar: GridToolbar,
              columnMenu: CustomColumnMenu,
            }}
            onCellEditStop={(params: any, event: any, details: any) => {
              console.log("cell edit", params, details);
            }}
            processRowUpdate={(updatedRow: any, originalRow: any) =>
              processRowUpdate(updatedRow)
            }
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            onProcessRowUpdateError={handleProcessRowUpdateError}
          />
        </Paper>
        <ActionTable
          actions={actions}
          onRunAction={(action) => runTableAction(action.id, "add_rows")}
          onEditAction={(action) => {
            setResearchOpen(true);
            setActionParameters({
              actionId: action.id,
              action: action.type,
              autoRunEnabled: action.autoRunEnabled,
              ...action.actionParameters,
            });
          }}
          onStopAction={(action: any) => {
            stopTableAction(action.id, action.type);
          }}
        />
        <Menu
          anchorEl={actionAnchor}
          open={actionAnchor !== null}
          onClose={() => {
            setActionAnchor(null);
            setSelectedActionId(null);
          }}
        >
          <MenuItem
            onClick={() => {
              runTableAction(selectedActionId, "update_rows");
              setActionAnchor(null);
              setSelectedActionId(null);
            }}
          >
            Run all rows that haven't run or have errors
          </MenuItem>
          <MenuItem
            onClick={() => {
              runTableAction(selectedActionId, "update_rows", undefined, true);
              setActionAnchor(null);
              setSelectedActionId(null);
            }}
            color={"danger"}
          >
            Force run all rows
          </MenuItem>
          {rowSelectionModel.length !== 0 && (
            <MenuItem
              onClick={() => {
                runTableAction(
                  selectedActionId,
                  "update_rows",
                  rowSelectionModel
                );
                setActionAnchor(null);
                setSelectedActionId(null);
              }}
            >
              Run on selected rows ({rowSelectionModel.length})
            </MenuItem>
          )}
        </Menu>
      </Box>
      <ImportModal
        open={importOpen}
        onClose={() => {
          setImportOpen(false);
          originalPoll(
            silentGetList,
            () => console.log("completed polling"),
            100
          );
        }}
        getToken={getToken}
        eventListener={eventListener}
        listId={listSelected.id}
        companyId={companyId}
      />
      <ResearchAssistantDialog
        open={researchOpen}
        onClose={() => {
          setResearchOpen(false);
          getList();
        }}
        headers={columns}
        listId={listSelected.id}
        companyId={companyId}
        getToken={getToken}
        eventListener={eventListener}
        currentPlan={currentPlan}
        actionParameters={actionParameters}
      />
      <AddColumnDialog
        open={addColumnOpen}
        onClose={() => setAddColumnOpen(false)}
        onSave={addColumn}
      />
      <WebhookDialog
        open={webhookOpen}
        onClose={() => setWebhookOpen(false)}
        listId={listSelected.id}
      />
      <TemplateEditor
        open={createTemplateOpen}
        onClose={() => setCreateTemplateOpen(false)}
        onConfirm={createListTemplate}
      />
    </Box>
  );
};
