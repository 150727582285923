import { Add, Delete, DragHandle, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default () => {
  const [steps, setSteps] = useState<Array<any>>([]);
  const [selectedStep, setSelectedStep] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<any>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<any>(false);
  const [editableContent, setEditableContent] = useState<any>({
    subject: "",
    content: "",
    daysAfterPrevious: 0,
  });

  const handleAddStep = () => {
    const newStep = {
      id: `step-${steps.length + 1}`,
      subject: `New Email ${steps.length + 1}`,
      content: "",
      daysAfterPrevious: 0,
      order: steps.length + 1,
    };
    setSteps([...steps, newStep]);
    setSelectedStep(newStep);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(steps);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSteps(items.map((item, index) => ({ ...item, order: index + 1 })));
  };

  const handleSaveStep = () => {
    const updatedSteps = steps.map((step) =>
      step.id === selectedStep.id ? { ...step, ...editableContent } : step
    );
    setSteps(updatedSteps);
    setIsEditing(false);
  };

  const handleDeleteStep = () => {
    const filteredSteps = steps.filter((step) => step.id !== selectedStep.id);
    setSteps(filteredSteps);
    setOpenDeleteDialog(false);
    setSelectedStep(filteredSteps[0] || null);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh", p: 2 }}>
      {/* Left Panel - Step List */}
      <Box sx={{ width: 300, mr: 2, borderRight: "1px solid #ddd" }}>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={handleAddStep}
          fullWidth
        >
          Add Step
        </Button>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="steps">
            {(provided: any) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {steps.map((step, index) => (
                  <Draggable key={step.id} draggableId={step.id} index={index}>
                    {(provided: any) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        selected={selectedStep?.id === step.id}
                        onClick={() => {
                          setSelectedStep(step);
                          setIsEditing(false);
                        }}
                        sx={{
                          mb: 1,
                          borderRadius: 1,
                          cursor: "pointer",
                          transition: "background-color 0.2s",
                          "&:hover": { backgroundColor: "#f5f5f5" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Box {...provided.dragHandleProps} sx={{ mr: 1 }}>
                            <DragHandle sx={{ cursor: "grab" }} />
                          </Box>
                          <ListItemText
                            primary={step.subject}
                            secondary={`Day ${step.daysAfterPrevious} | Step ${step.order}`}
                          />
                          <IconButton onClick={() => setIsEditing(true)}>
                            <Edit fontSize="small" />
                          </IconButton>
                        </Box>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      {/* Right Panel - Step Editor */}
      {selectedStep && (
        <Box sx={{ flex: 1, pl: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">
              {isEditing ? "Edit Step" : selectedStep.subject}
            </Typography>
            <Button
              color="error"
              startIcon={<Delete />}
              onClick={() => setOpenDeleteDialog(true)}
            >
              Delete Step
            </Button>
          </Box>

          {isEditing ? (
            <>
              <TextField
                label="Subject"
                fullWidth
                value={editableContent.subject}
                onChange={(e) =>
                  setEditableContent({
                    ...editableContent,
                    subject: e.target.value,
                  })
                }
                sx={{ mb: 2 }}
              />
              <TextField
                label="Days After Previous"
                type="number"
                fullWidth
                value={editableContent.daysAfterPrevious}
                onChange={(e) =>
                  setEditableContent({
                    ...editableContent,
                    daysAfterPrevious: e.target.value,
                  })
                }
                sx={{ mb: 2 }}
              />
              <TextField
                label="Email Content"
                multiline
                rows={10}
                fullWidth
                value={editableContent.content}
                onChange={(e) =>
                  setEditableContent({
                    ...editableContent,
                    content: e.target.value,
                  })
                }
                sx={{ mb: 2 }}
              />
              <Button variant="contained" onClick={handleSaveStep}>
                Save Changes
              </Button>
            </>
          ) : (
            <Box
              sx={{
                border: "1px solid #eee",
                borderRadius: 1,
                p: 2,
                whiteSpace: "pre-wrap",
                minHeight: 300,
              }}
            >
              {selectedStep.content || "No content entered yet"}
            </Box>
          )}
        </Box>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Step</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this email step? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteStep} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
