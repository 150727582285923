import AccountTreeOutlined from "@mui/icons-material/AccountTreeOutlined";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import ListAltOutlined from "@mui/icons-material/ListAltOutlined";
import PlayCircleOutlined from "@mui/icons-material/PlayCircleOutlined";
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import LoadingScreen from "../../components/LoadingScreen";
import { Company } from "../../types";
import { formatTimestamp, hostname, parseError } from "../../utils";
import WorkflowEditor from "./WorkflowEditor";
import WorkflowRunList from "./WorkflowRunList";
import WorkflowTriggerMenu from "./WorkflowTriggerMenu";

export default ({
  mode,
  currentUser,
  getToken,
  eventListener,
  companyId,
  company,
  currentPlan,
}: {
  mode: string;
  getToken: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
  company: Company;
  currentPlan: string;
}) => {
  const [init, setInit] = useState(false);
  const [workflows, setWorkflows] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [workflowOpen, setWorkflowOpen] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>({});
  const [triggerMenuOpen, setTriggerMenuOpen] = useState(false);
  const [runListOpen, setRunListOpen] = useState(false);

  const getWorkflows = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/workflows`, {
        params: {
          companyId,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setWorkflows(res.data.workflows);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshData = async () => {
    setRefreshing(true);
    await getWorkflows();
    setRefreshing(false);
  };

  useEffect(() => {
    if (currentUser.uid) {
      getWorkflows();
    }
  }, [currentUser.uid]);

  if (loading) {
    return <LoadingScreen open={true} />;
  }

  const deleteWorkflow = async (workflowId: string) => {
    const currentToken = await getToken();
    await axios
      .delete(`${hostname}/workflows/${workflowId}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setWorkflows(workflows.filter((w) => w.id !== workflowId));
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const editWorkflow = (workflow: any) => {
    setSelectedWorkflow(workflow);
    setWorkflowOpen(true);
  };

  const createWorkflow = () => {
    setSelectedWorkflow({});
    setWorkflowOpen(true);
  };

  const playWorkflow = (workflow: any) => {
    setSelectedWorkflow(workflow);
    setTriggerMenuOpen(true);
  };

  const showWorkflowRuns = (workflow: any) => {
    setSelectedWorkflow(workflow);
    setRunListOpen(true);
  };

  return (
    <Box>
      <WorkflowTriggerMenu
        open={triggerMenuOpen}
        onClose={() => setTriggerMenuOpen(false)}
        workflow={selectedWorkflow}
        eventListener={eventListener}
        companyId={companyId}
        getToken={getToken}
      />
      <WorkflowRunList
        open={runListOpen}
        onClose={() => setRunListOpen(false)}
        workflow={selectedWorkflow}
        eventListener={eventListener}
        companyId={companyId}
        getToken={getToken}
      />
      <WorkflowEditor
        open={workflowOpen}
        workflow={selectedWorkflow}
        onClose={() => {
          refreshData();
          setWorkflowOpen(false);
        }}
        mode={mode}
        currentUser={currentUser}
        company={company}
        companyId={companyId}
        getToken={getToken}
        eventListener={eventListener}
        currentPlan={currentPlan}
      />
      <Button
        style={{ marginTop: 10 }}
        startIcon={<AccountTreeOutlined />}
        onClick={createWorkflow}
      >
        Create Workflow
      </Button>
      <Typography sx={{ mt: 2 }}>
        Workflows is a beta feature with limited functionality at the moment.
        Please contact us if you have any questions or feedback.
      </Typography>
      <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
        {refreshing && <LinearProgress />}
        {!refreshing && <div style={{ height: 4 }}></div>}
        <IconButton
          onClick={refreshData}
          style={{ float: "right", marginRight: 5 }}
        >
          <RefreshOutlined />
        </IconButton>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  Name
                </TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workflows.map((workflow) => (
                <TableRow key={workflow.id}>
                  <TableCell>{workflow.name}</TableCell>
                  <TableCell>
                    {formatTimestamp(workflow.created).fullDateTime}
                  </TableCell>
                  <TableCell>
                    {formatTimestamp(workflow.updated).fullDateTime}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => playWorkflow(workflow)}>
                      <PlayCircleOutlined />
                    </IconButton>
                    <IconButton onClick={() => showWorkflowRuns(workflow)}>
                      <ListAltOutlined />
                    </IconButton>
                    <IconButton onClick={() => editWorkflow(workflow)}>
                      <EditOutlined />
                    </IconButton>
                    <IconButton onClick={() => deleteWorkflow(workflow.id)}>
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
