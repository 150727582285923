// components/DynamicFavicon.tsx
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { generateFaviconSVG } from "../utils";

interface DynamicFaviconProps {
  instanceConfig: any;
}

export const InstanceHead: React.FC<DynamicFaviconProps> = ({
  instanceConfig,
}) => {
  useEffect(() => {
    // Remove any existing favicons
    const existingFavicons = document.querySelectorAll('link[rel*="icon"]');
    existingFavicons.forEach((favicon) => favicon.remove());

    // Cleanup on component unmount
    return () => {
      const dynamicFavicon = document.querySelector('link[rel="icon"]');
      if (dynamicFavicon) {
        dynamicFavicon.remove();
      }
    };
  }, [instanceConfig]);

  const faviconUrl = useMemo(() => {
    return generateFaviconSVG(
      instanceConfig.faviconText,
      instanceConfig.primaryColor
    );
  }, [instanceConfig]);

  // @ts-ignore
  const GenerateHead = () => {
    if (instanceConfig.title === "Mercenary Marketing AI") {
      return (
        <Helmet>
          <title>{instanceConfig.headTitle}</title>
          <meta itemprop="name" content="app.mercenarymarketing.ai"></meta>
          <link
            rel="icon"
            type="image/x-icon"
            media="(prefers-color-scheme: dark)"
            href="https://images.squarespace-cdn.com/content/v1/66d50c2db7979b78924375db/6952b0d5-48bb-41c9-814e-a6622e6238ee/favicon.ico?format=100w"
          ></link>
          <meta
            itemprop="thumbnailUrl"
            content="http://static1.squarespace.com/static/66d50c2db7979b78924375db/t/676d1964faea2d16a9604368/1735203173086/Final+GLI-MMai+image.png?format=1500w"
          ></meta>
          <link
            rel="image_src"
            href="http://static1.squarespace.com/static/66d50c2db7979b78924375db/t/676d1964faea2d16a9604368/1735203173086/Final+GLI-MMai+image.png?format=1500w"
          ></link>
          <meta
            itemprop="image"
            content="http://static1.squarespace.com/static/66d50c2db7979b78924375db/t/676d1964faea2d16a9604368/1735203173086/Final+GLI-MMai+image.png?format=1500w"
          ></meta>
          <meta
            name="description"
            content="MercenaryMarketing.ai offers cutting-edge AI-driven strategies and creative
solutions to elevate your small business (SMB) or thought-leadership
content. From personalized campaigns to advanced analytics, we help you
dominate your market with precision and innovation. Partner with us for
results that matter. Services include content creation, paid digital media,
and AI assistants / chatbots."
          ></meta>
          {/* Fallback favicon for browsers that don't support SVG */}
          <link rel="alternate icon" type="image/png" href="/favicon.png" />
        </Helmet>
      );
    }
    return (
      <Helmet>
        <title>{instanceConfig.headTitle}</title>
        <link rel="icon" type="image/svg+xml" href={faviconUrl} />
        <link rel="icon" type="image/svg+xml" sizes="16x16" href={faviconUrl} />
        <link rel="icon" type="image/svg+xml" sizes="32x32" href={faviconUrl} />
        <link rel="alternate icon" type="image/png" href="/favicon.png" />
      </Helmet>
    );
  };

  return <GenerateHead />;
};
