import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color: "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: "1px solid rgba(81, 81, 81, 1)",
    ...theme.applyStyles("light", {
      borderRightColor: "rgba(224, 224, 224, 1)",
    }),
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: "1px solid rgba(81, 81, 81, 1)",
    ...theme.applyStyles("light", {
      borderBottomColor: "rgba(224, 224, 224, 1)",
    }),
  },
  "& .MuiDataGrid-cell": {
    color: "rgba(255,255,255,0.65)",
    ...theme.applyStyles("light", {
      color: "rgba(0,0,0,.85)",
    }),
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
}));
